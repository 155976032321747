<template>
  <v-container
    class="fill-height"
    style="font-family: NotoSanLao;"
  >
    <v-row justify="center">
      <v-col cols="auto">
        <router-link to="/">
          <v-img
            class="mx-auto mb-6"
            width="120"
            :src="require('@/assets/LaoPT-Logo.webp')"
          />
        </router-link>
        <v-card width="460">
          <v-card-text class="text-center px-12 py-16">
            <validation-observer
              ref="observer"
              v-slot="{ invalid }"
            >
              <v-form
                ref="form"
                @submit.prevent="signUp"
              >
                <!-- <div class="text-h4 font-weight-black mb-10">
                  ເຂົ້າສູ່ລະບົບ
                </div> -->
                <h1 class="mb-10">
                  ເຂົ້າສູ່ລະບົບ
                </h1>
                <validation-provider
                  v-slot="{ errors }"
                  name="ອີເມວ"
                  :rules="{
                    required: true
                  }"
                >
                  <v-text-field
                    v-model="email"
                    label="ອີເມວ"
                    clearable
                    prepend-icon="mdi-email"
                    :error-messages="errors"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="ລະຫັດຜ່ານ"
                  :rules="{
                    required: true
                  }"
                >
                  <v-text-field
                    v-model="password"
                    label="ລະຫັດຜ່ານ"
                    clearable
                    prepend-icon="mdi-lock-outline"
                    :error-messages="errors"
                  />
                </validation-provider>

                <v-btn
                  class="mt-6"
                  type="submit"
                  block
                  x-large
                  rounded
                  color="primary"
                  :disabled="invalid"
                >
                  ເຂົ້າສູ່ລະບົບ
                </v-btn>
              </v-form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "SignUp",
  data: () => ({
    email: null,
    password: null
  }),
  methods: {
    async signUp() {
      const result = await this.$refs.observer.validate();
      if (result) {
        this.$router.push("/");
      }
    }
  }
};
</script>
<style lang=""></style>
