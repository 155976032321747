var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height",staticStyle:{"font-family":"NotoSanLao"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{staticClass:"mx-auto mb-6",attrs:{"width":"120","src":require('@/assets/LaoPT-Logo.webp')}})],1),_c('v-card',{attrs:{"width":"460"}},[_c('v-card-text',{staticClass:"text-center px-12 py-16"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.signUp($event)}}},[_c('h1',{staticClass:"mb-10"},[_vm._v(" ເຂົ້າສູ່ລະບົບ ")]),_c('validation-provider',{attrs:{"name":"ອີເມວ","rules":{
                  required: true
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"ອີເມວ","clearable":"","prepend-icon":"mdi-email","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"ລະຫັດຜ່ານ","rules":{
                  required: true
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"ລະຫັດຜ່ານ","clearable":"","prepend-icon":"mdi-lock-outline","error-messages":errors},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('v-btn',{staticClass:"mt-6",attrs:{"type":"submit","block":"","x-large":"","rounded":"","color":"primary","disabled":invalid}},[_vm._v(" ເຂົ້າສູ່ລະບົບ ")])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }